<template>
  <span>
    <!-- new Events -->
    <Events :del="true" :attendance="true" :goingCount="true"></Events>
    <v-switch v-model="showOldEvents" label="Show old events" />
    <!-- Old Events -->
    <Events
      v-if="showOldEvents"
      title="All our old events"
      :attendance="true"
      :del="true"
      :goingCount="true"
      :query="
        (event) =>
          event
            .where(
              'date',
              '<=',
              new Date(new Date().setDate(new Date().getDate() - 1))
            )
            .orderBy('date')
      "
    ></Events>

    <v-btn
      fixed
      dark
      fab
      style="bottom: 80px"
      right
      large
      color="pink"
      @click="showNewEventDialog = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="showNewEventDialog" max-width="700">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Create Event</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newEvent.name"
                  prepend-icon="mdi-calendar-blank"
                  label="Name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  prepend-icon="mdi-calendar-text"
                  v-model="newEvent.description"
                  label="Description*"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  accept="image/png, image/jpeg, image/bmp, image/jpg"
                  prepend-icon="mdi-file-image"
                  v-model="bannerFile"
                  label="Banner*"
                ></v-file-input>
              </v-col>
              <v-col cols="12" xs="12" md="6">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="computedDateFormatted"
                      label="Date*"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="timestamp.date"
                    type="date"
                    @input="menuDate = false"
                    show-current
                    :events="eventDates"
                    :min="toDateFormat(new Date())"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" xs="12" md="6">
                <v-menu
                  ref="menuTime"
                  v-model="menuTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="timestamp.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="timestamp.time"
                      label="Time*"
                      prepend-icon="access_time"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="menuTime"
                    v-model="timestamp.time"
                    full-width
                    @click:minute="$refs.menuTime.save(timestamp.time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="newEvent.locationName"
                  prepend-icon="mdi-map"
                  label="Location Name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="newEvent.link"
                  prepend-icon="mdi-link"
                  label="Link (Facebook?)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="newEvent.over18"
                  prepend-icon="mdi-account-off"
                  :label="`Allow over 18s only: ${newEvent.over18}`"
                />
              </v-col>
              <v-col cols="12">
                <v-slider
                  v-model="newEvent.points"
                  class="align-center"
                  :max="50"
                  :min="0"
                  hide-details
                  label="Points awarded for attending*"
                  prepend-icon="mdi-cards-diamond"
                  required
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="newEvent.points"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                    ></v-text-field>
                  </template>
                </v-slider>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showNewEventDialog = false">
            Close
          </v-btn>
          <v-btn color="success" text @click="save()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { db, storage } from "@/plugins/firebase";
import Events from "@/components/Event/Events";

export default {
  fiery: true,
  components: { Events },
  data() {
    return {
      loading: false,
      showOldEvents: false,
      menuTime: null,
      menuDate: null,
      timestamp: {
        date: null,
        time: null,
      },
      newEvent: {
        name: "",
        description: "",
        locationName: "",
        link: "",
        banner: "",
        points: 0,
        over18: false,
      },
      bannerFile: null,
      showNewEventDialog: false,
      events: this.$fiery(db.collection("Events"), {
        key: "id",
      }),
    };
  },
  computed: {
    newEventWithDate() {
      const newEvent = this.newEvent;
      if (this.timestamp.date && this.timestamp.time) {
        const date = new Date(`${this.timestamp.date} ${this.timestamp.time}`);
        return { ...newEvent, date: date };
      } else {
        return { ...newEvent, date: null };
      }
    },
    computedDateFormatted() {
      return this.formatDate(this.timestamp.date);
    },
    eventDates() {
      return this.events.map((event) => {
        const date = event.date.toDate();
        return this.toDateFormat(date);
      });
    },
  },
  methods: {
    toDateFormat(date) {
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);

      return `${year}-${month}-${day}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    save() {
      if (
        this.newEvent.name &&
        this.newEvent.description &&
        this.newEvent.locationName &&
        this.timestamp.date &&
        this.timestamp.time
      ) {
        this.loading = true;
        const savedNewEvent = this.$fiery.create(
          this.events,
          this.newEventWithDate
        );
        const id = savedNewEvent[".uid"].split("/").slice(-1)[0];
        db.collection("ExpectedAttendance")
          .doc(id)
          .set({ name: this.newEvent.name });
        const path = "events/" + id;
        storage
          .child(path)
          .put(this.bannerFile)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((downloadURL) => {
              savedNewEvent.banner = downloadURL;
              this.$fiery.update(savedNewEvent).then(() => {
                // reset
                this.loading = false;
                this.showNewEventDialog = false;
                this.bannerFile = null;
                this.newEvent = {
                  name: "",
                  description: "",
                  locationName: "",
                  link: "",
                  points: 0,
                  over18: false,
                };
                this.timestamp = {
                  date: null,
                  time: null,
                };
              });
            });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
